<template>

  <div>

   <Header :topoFundo="topoFundo" :topoCor="topoCor" />


    <!-- Breadcrumb -->
    <div class="site-breadcrumb" style="height:200px;background-color:#295089;">
      <div class="container">
        <div class="row">
          <div class="col-md-12" style="text-align:center;padding-top:50px;">
               <span style="color:#ffffff;font-size:45px;">{{ topoTitulo }}</span>
          </div>
        </div>
        
      </div>
    </div>

    <!-- Main -->
    <section class="page-section single-blog" style="padding-top:60px;padding-bottom:90px;">
      <div class="container">
        <div class="row">
          <div class="col-11 col-md-5 col-xl-5 mb-5">
            <div id="admin-sidebar" class="col-md-10 p-x-0 p-y-3">
              <ul class="sidenav admin-sidenav list-unstyled">
                <a href="" class="cursor" v-for="servico in servicos" :key="servico.id" @click.prevent="carregaServico(servico.institucionalId)">
                  <li class="text-black" style="padding:20px;border-bottom:1px solid #e4e9f0;">{{ servico.nome }} <span style="padding-right:5px;"> {{ servico.referencia }}</span> </li>
                </a>
              </ul>
            </div> <!-- /#admin-sidebar -->
          </div>
          <div class="col-xl-7 singel-blog-content">
            <h2 class="heading" style="color:#000000;">{{ servicoTitulo }} <strong class="" style="color:#00ccff;padding-right:5px;">{{ servicoSubTitulo }}</strong></h2>
            <p v-html="servicoDescricao"></p>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import Header from '../shared/Header.vue'
// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    Header,
    // lingallery
  },
  data() {
    return {
      topoFundo:'#13204A',
      topoCor:'ajustacor',
      topoInternas: [],
      topoTitulo: '',
      topo_bg: '',

      servicos: [],
      servicoTitulo: '',
      servicoSubTitulo:'',
      servicoDescricao: '',



    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20servicos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      //this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/cadservicos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.servicos = retornoAPI.filter((x) => x.visivel)

      this.servicos.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })

      this.servicoTitulo = this.servicos[0].nome
      this.servicoSubTitulo = this.servicos[0].referencia
      this.servicoDescricao = this.servicos[0].descricao1

    });

    this.carregaServico(this.$route.params.idServico)

  },
  methods: {
    carregaServico(idServico) {    
      this.$http
      .get(this.$apiUrl + "/institucional/" + idServico)
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.servico = retornoAPI

        this.servicoTitulo = this.servico.nome
        this.servicoSubTitulo = this.servico.referencia
        this.servicoDescricao = this.servico.descricao1
        
          
      });
    }

  }
  
}

</script>

