<template>
  
   <div class="site-footer" style="border-top:1px solid #cccccc;">
    <div class="container">

      <div class="row">
        <div class="col-lg-3" style="text-align:center;">
          <div class="widget">
            
             <router-link to="/" class="navbar-brand">
                <img class="img-fluid" src="/images/logo.png" alt="Logo" style="border-radius:5px;"  />
             </router-link>
            <ul class="list-unstyled social" style="padding-top:30px;">
              <li><a v-if="instagramLink !=''" :href="instagramLink" target="_blank"><span class="icon-instagram"></span></a></li>
              <li><a v-if="twitterLink !=''" :href="twitterLink" target="_blank"><span class="icon-twitter"></span></a></li>
              <li><a v-if="facebookLink !=''" :href="facebookLink" target="_blank"><span class="icon-facebook"></span></a></li>
              <li><a v-if="likedinLink !=''" :href="likedinLink" target="_blank"><span class="icon-linkedin"></span></a></li>
            </ul>
          </div> <!-- /.widget -->
        </div> <!-- /.col-lg-3 -->

        <div class="col-lg-2">
          <div class="widget">
            <h3>Home</h3>
            <ul class="list-unstyled float-left links">
              <li><router-link to="/">Home</router-link></li>
             <!--   <li><router-link to="/servicos">Serviços</router-link></li> -->
              <li><router-link to="/sobre">Sobre nós</router-link></li>
              <li><router-link to="/blog">Blog</router-link></li>
              <li><router-link to="/contato">Contato</router-link></li>
            </ul>
          </div> <!-- /.widget -->
        </div> <!-- /.col-lg-3 -->

        <div class="col-lg-3">
          <div class="widget">
            <h3>Áreas de Atuação</h3>
            <ul class="list-unstyled float-left links" style="width: 100% !important;">
              <li v-for="area in servicos" :key="area.institucionalId"><a href="#" @click="$redirect_reload('/atuacao/' + area.institucionalId + '/' + area.nome.replaceAll(' ','-').replaceAll('/','') + '-' + area.referencia.replaceAll(' ','-').replaceAll('/',''))">{{ area.nome}} {{ area.referencia}}</a></li>
            </ul>
          </div> <!-- /.widget -->
        </div> <!-- /.col-lg-3 -->


        <div class="col-lg-4">
          <div class="widget">
            <h3>Contato</h3>
            <p v-html="footerEndereco"></p>

          </div> <!-- /.widget -->
        </div> <!-- /.col-lg-3 -->

      </div> <!-- /.row -->



      
      
      <div class="row mt-5">
        <div class="col-12 text-center">
                   <a href="https://avancedigital.com.br/" target="_blank">
          <img class="logo_avance" src="/images/logo_avance.png">
        </a>   
        </div>
      </div>
    </div> <!-- /.container -->
     
  </div> <!-- /.site-footer -->

 

</template>


<script>
export default {
  data() {
    return {

      footer: [],
      footerEndereco: '',
      footerTelefone: '',
      footerEmail: '',
      footer_bg: '',
      servicos:[],
      

      socialFacebook: [],
      facebookLink: '',

      socialInstagram: [],
      instagramLink: '',

      socialLikedin: [],
      likedinLink: '',

      socialTwitter: [],
      twitterLink: '',

      contatoEmail:'',
      email:'',

      // Objeto Contato      
      Contato: {
        NomeEmpresa: "Luzardo Advogados Associados ",
        Assunto: "Newsletter",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {

     this.$http
    .get(this.$apiUrl + "/institucional/telas/informacoes")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.footer = retornoAPI

      this.footerEndereco = this.footer[0].resumo1

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/facebook")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialFacebook = retornoAPI.filter((x) => x.visivel)

      this.facebookLink = this.socialFacebook[0].linkBtn1

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/instagram")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialInstagram = retornoAPI.filter((x) => x.visivel)

      this.instagramLink = this.socialInstagram[0].linkBtn1

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/linkedin")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialLikedin = retornoAPI.filter((x) => x.visivel)

      this.likedinLink = this.socialLikedin[0].linkBtn1

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/twitter")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialTwitter = retornoAPI.filter((x) => x.visivel)

      this.twitterLink = this.socialTwitter[0].linkBtn1

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/cadastro%20areas")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.servicos = retornoAPI.filter((x) => x.visivel)

      this.servicos.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })

    });

  },
  methods: {
    enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      this.Contato.Destinatario = this.contatoEmail
      // this.Contato.Destinatario = "thiagokdma@gmail.com"

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = 
        "Email: " + this.email

      //validar vazios
      if(this.email == '') {
        this.$mensagem_normal("Preencha todos os campos");
      
      }else {

        //SE NAO TIVER ANEXO:
        // console.log(this.Contato)

        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
            alert("Enviado com sucesso!")
            this.email = ''
            this.Contato.Assunto=""
        },error => {
          alert(error.bodyText)
          this.email = ''
          this.Contato.Assunto=""
        });

      }
     
    }
  }
}
</script>

<style>

h3 {
    font-size: 16px;
    color: #000000 !important;
    font-weight: 800 !important;
}

a {
  color: #333333 !important;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; 
  text-decoration: none !important; 
  }

  a:hover {
    color: #00ccff !important;
    text-decoration: none !important; 
    }

   .site-footer a:before {
    position: absolute;
    left: 0;
    right: 0;
    content: "";
    background: rgba(180, 156, 115, 0.1);
    height: 0 !important; 
    width: 100%;
    bottom: 0;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}
.site-footer .social li {
    padding: 2px !important;
}

.site-footer .social li a {
    display: inline-block;
    width: 35px !important;
    height: 35px !important;
    position: relative;
    background: #00ccff !important;
    border-radius: 50% !important;
    color: #ffffff !important;
}
.site-footer {
    padding: 60px 0px 20px 0px !important;
}
</style>