<template>
  <div id="app">

    <div v-html="codigo_SEO"></div>

   <!--   <Header />  -->

    <transition name="fadeIn">
      <router-view></router-view>
    </transition>

    <Footer />

  </div>
</template>

<script>

 // import Header from './components/site/shared/Header.vue'
  import Footer from './components/site/shared/Footer.vue'

  export default {
    name: 'App',
    components: {
      // Header,
      Footer
    },
    data() {
      return {

        seobody: [],
        codigo_SEO: '',


        seoHead:[],
        codigoHead: '',


        seoAnalitics:[],
        codigoAnalitics: '',


        metaHome: [],
        codigoHome: ''
        
      }
    },
    created() {

      this.$http
      .get(this.$apiUrl + "/seobody/")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.seobody = retornoAPI
        this.codigo_SEO = this.seobody[0].codigo
      });


      this.$http
      .get(this.$apiUrl + "/seohead/")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.seoHead = retornoAPI;
        this.codigoHead = this.seoHead[0].codigo;
      });


      this.$http
      .get(this.$apiUrl + "/seoanalitycs/")
      .then((res) => res.json())
      .then((idi) => {
        this.seoAnalitics = idi;
        this.codigoAnalitics = this.seoAnalitics[0].codigo;
      });


      this.$http
      .get(this.$apiUrl + "/Seometahome/")
      .then((res) => res.json())
      .then((idi) => {
        this.metaHome = idi;
        this.codigoHome = this.metaHome[0].codigo;
        document.head.innerHTML += this.codigoHead.trim('"') + this.codigoAnalitics.trim('"') + this.codigoHome.trim('"');
      });
    }
  }
</script>

<style>

  /* css tmp */
  @import '/css/style.css';
  @import '/css/avanceDigital.css';


  /* ---------  */
  .ql-align-justify {
  text-align: justify !important;
  }
  .ql-align-right{
  text-align: right !important;
  }

  .ql-align-center{
  text-align: center !important;
  }


  /*---------- Animations */
  .fadeIn-enter {
    opacity: 0;
  }
  .fadeIn-enter-active {
    transition: opacity .8s;
  }
  .fadeIn-enter-to {
  opacity: 1;
  }

@media (max-width:768px) {
    .hidden-xs {
        display: none !important;
    }

  .untree_co--hero, .untree_co--hero > .container > .row {
    height: 20vh !important;
    min-height: 0px;
  }

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #333333 !important;
    text-align: center;
}
}

@media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
        display: none !important;
    }

    .untree_co--hero, .untree_co--hero > .container > .row {
    height: 30vh;
  }
}

@media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width:1200px) {
    .hidden-lg {
        display: none !important;
    }
}
</style>
