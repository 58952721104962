<template>

  <div>
    <Header :topoFundo="topoFundo" :topoCor="topoCor" />

    <!-- Breadcrumb -->
    <div class="site-breadcrumb" style="height:200px;background-color:#295089;">
      <div class="container">
        <div class="row">
          <div class="col-md-12" style="text-align:center;padding-top:50px;">
               <span style="color:#ffffff;font-size:45px;">{{ topoTitulo}}</span>
          </div>
        </div>
        
      </div>
    </div>

    <!-- Sobre -->
    <section class="hidden-sm hidden-xs" :style="{ backgroundImage: 'url('+ sobreImg + ')', height:'700px', backgroundRepeat:'no-repeat', }">
      <div class="container">
        <div class="row">
          <div class="col-md-7" >
          </div>
          <div class="col-md-5 mt-5">
              <div class="col-lg-12">
                <h2 class="heading" style="color:#000000;"><strong class="" style="color:#00ccff;">{{ sobreTituloAzul }}</strong> {{ sobreTituloPreto }}</h2>
              </div> 
              <div class="col-lg-12 mt-5">
                 <p v-html="sobreDescricao"></p>
              </div>
              <div v-for="item in itens" :key="item.institucionalId" class="col-lg-12 mt-5">
                  <div style="display:inline-flex;">
                  <img v-if="item.institucionalImg!=''" :src="$apiUrlIndex + item.institucionalImg[0].urlImagem" style="height: 30px;padding-right: 5px;">
                  <p v-html="item.nome"></p>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </section>
     <section class="hidden-lg hidden-md">
      <div class="container">
        <div class="row">
          <div class="col-md-7" >
          </div>
          <div class="col-md-5 mt-5">
              <div class="col-lg-12">
                <h2 class="heading" style="color:#000000;"><strong class="" style="color:#00ccff;">{{ sobreTituloAzul }}</strong> {{ sobreTituloPreto }}</h2>
              </div> 
              <div class="col-lg-12 mt-5">
                 <p v-html="sobreDescricao"></p>
              </div>
              <div v-for="item in itens" :key="item.institucionalId" class="col-lg-12 mt-5">
                  <div style="display:inline-flex;">
                  <img v-if="item.institucionalImg!=''" :src="$apiUrlIndex + item.institucionalImg[0].urlImagem" style="height: 30px;padding-right: 5px;">
                  <p v-html="item.nome"></p>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </section>

    <section style="background-color:#e4e9f0;padding-top:30px;padding-bottom:80px;">
      <div class="container">
        <div class="row">
          <div class="col-md-12 mt-5 mb-5">
            <h2 class="heading" style="color:#000000;">{{ parceiroTituloPreto }} <strong class="" style="color:#00ccff;">{{ parceiroTituloAzul }}</strong> </h2>
          </div>

            <div v-for="cadastro in CadastroParceiros" :key="cadastro.institucionalId" class="col-md-4" style="text-align:center;">
              <div style="padding:20px;background-color:#ffffff;border-radius:10px;height: 100%;">                 
                <img v-if="cadastro.institucionalImg!=''" :src="$apiUrlIndex + cadastro.institucionalImg[0].urlImagem" style="border-radius:50%;width:100px;">
                 <h3 style="font-size:20px;padding-top: 10px;">{{ cadastro.nome}}</h3>
                 <h4 style="font-size:16px;">{{ cadastro.referencia}}</h4>
                 <p v-html="cadastro.descricao1" style="font-size:14px;"></p>
                </div>
            </div>
          </div>
      </div>
    </section>
    
    <section style="background-color:#f4f6f7;padding-top:120px;padding-bottom:120px;">
      <div class="container">
        <div class="row">
            <div v-for="missaos in Missao" :key="missaos.institucionalId" class="col-md-4" style="text-align:center;">             
                 <h3 style="color:#00ccff !important;">{{ missaos.nome}}</h3>
                 <p v-html="missaos.descricao1"></p>
            </div>
          </div>
      </div>
    </section>

    <div class="untree_co-cta overlay" :style="{ backgroundImage: 'url('+ BannerImg + ')' }">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-lg-7">
          <h2 class="heading" style="color:#FFFFFF;">{{ BannerPreto }}<strong class="" style="color:#00ccff;">{{ BannerAzul }}</strong></h2>
          <p class="mb-0" v-html="BannerDescricao" style="color:#ffffff;"></p>
          <a class="btn btn-primary" :href="BannerLinkBtn" target="_blank" style="background-color:#00ccff;border:none;color:#ffffff !important;padding:15px;border-radius:30px;z-index:99999;position: relative;">{{ BannerTxtBtn }}</a>
        </div> <!-- /.col-lg-10 -->
      </div> <!-- /.row -->
    </div> <!-- /.untree_co-cta -->
  </div>
</template>

<script>
 import Header from '../shared/Header.vue'
// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
     Header,
    // Depoimentos
  },
  data() {
    return {
      topoFundo:'#13204A',
      topoCor:'ajustacor',
      topoInternas: [],
      topoTitulo: '',
      topo_bg: '',

      sobre: [],
      sobreImg: '',
      sobreTituloPreto: '',
      sobreTituloAzul: '',
      sobreDescricao: '',
      itens:[],

      ParceirosTitulo: [],
      parceiroTituloPreto: '',
      parceiroTituloAzul:'',

      CadastroParceiros:[],
      Missao:[],
      Bannerinferior:[],
      BannerPreto:'',
      BannerAzul:'',
      BannerDescricao:'',
      BannerTxtBtn:'',
      BannerLinkBtn:'',
      BannerImg:'',
    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20sobre")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome
      //this.topo_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/descricao%20sobre")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.sobre = retornoAPI

      this.sobreTituloPreto = this.sobre[0].nome
      this.sobreTituloAzul = this.sobre[0].referencia
      this.sobreDescricao = this.sobre[0].descricao1
      this.sobreImg = this.$apiUrlIndex + this.sobre[0].institucionalImg[0].urlImagem

    });

this.$http
    .get(this.$apiUrl + "/institucional/telas/itensdescrição")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.itens = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
    });



    this.$http
    .get(this.$apiUrl + "/institucional/telas/parceirostítulo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.ParceirosTitulo = retornoAPI

      this.parceiroTituloPreto = this.ParceirosTitulo[0].nome
      this.parceiroTituloAzul = this.ParceirosTitulo[0].referencia
    });

   this.$http
    .get(this.$apiUrl + "/institucional/telas/cadastroparceiros")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.CadastroParceiros = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
    });

     this.$http
    .get(this.$apiUrl + "/institucional/telas/missao")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.Missao = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
    });

     this.$http
    .get(this.$apiUrl + "/institucional/telas/bannerinferior")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.Bannerinferior = retornoAPI

    if (this.Bannerinferior!= ''){
      this.BannerPreto = this.Bannerinferior[0].nome
      this.BannerAzul = this.Bannerinferior[0].referencia
      this.BannerDescricao = this.Bannerinferior[0].descricao1
      this.BannerTxtBtn = this.Bannerinferior[0].textoBtn1
      this.BannerLinkBtn = this.Bannerinferior[0].linkBtn1
      if (this.Bannerinferior[0].institucionalImg!= ''){
          this.BannerImg= this.$apiUrlIndex + this.Bannerinferior[0].institucionalImg[0].urlImagem;
      }
      
    }
     


    });




  }
  
}

</script>

