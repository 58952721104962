import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/components/site/views/Home.vue'
import Atuacao from '@/components/site/views/Atuacao.vue'
import Servicos from '@/components/site/views/Servicos.vue'
import Sobre from '@/components/site/views/Sobre.vue'
import Blog from '@/components/site/views/Blog.vue'
import Contato from '@/components/site/views/Contato.vue'


Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if(savedPosition) {
          return resolve(savedPosition)
        }
        if(to.hash) {
          return resolve({
            selector: to.hash,
            offset: {x: 0, y: 0}
          })
        }
        return resolve({ 
          x: 0, y: 0,
          behavior: 'smooth'
        })
      }, 700) 
    })
  },
  routes: [
    {path: '/', component: Home},
    {path: '/atuacao', component: Atuacao},
    {path: '/atuacao/:idServico/:nome', component: Atuacao},
    {path: '/servicos', component: Servicos},
    {path: '/sobre', component: Sobre},
    {path: '/blog/', component: Blog},
    {path: '/contato', component: Contato},

  ]
})