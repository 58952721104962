<template>

  <div>
     <Header :topoFundo="topoFundo" :topoCor="topoCor" />

 <!-- Carousel -->
    <div class="">
      <b-carousel id="carousel-fade" fade controls indicators class="untree_co--hero overlay">
        <b-carousel-slide v-for="slide in slider" :key="slide.institucionalId" :img-src="$apiUrlIndex + slide.institucionalImg[0].urlImagem">
             <div class="container hidden-sm hidden-xs" style="padding-bottom: 10%;">
                <div class="row align-items-center">
                  <div class="col-md-7"></div>
                  <div class="col-md-5 ml-auto">
                    <div class="intro">
                      <h1 class="mb-4"><strong>{{ slide.nome }}</strong></h1>
                      <div class="intro-desc"><p class="mb-4" v-html="slide.descricao1"></p>
                        <p><a :href="slide.linkBtn1" class="btn btn-primary" style="background-color:#00ccff;border:none;color:#ffffff !important;padding:20px;border-radius:40px;">{{ slide.textoBtn1 }}</a></p>
                      </div>
                    </div> 
                  </div> 
                </div>
              </div> 
              <div class="container hidden-md hidden-lg" style="padding-bottom: 10%;">
                <div class="row align-items-center">
                  <div class="col-md-7"></div>
                  <div class="col-md-5 ml-auto">
                    <div class="intro">
                      <h6 class="mb-4"><strong>{{ slide.nome }}</strong></h6>
                      <div class="intro-desc hidden-sm hidden-xs"><p class="mb-4" v-html="slide.descricao1"></p>
                        <p><a :href="slide.linkBtn1" class="btn btn-primary" style="background-color:#00ccff;border:none;color:#ffffff !important;padding:20px;border-radius:40px;">{{ slide.textoBtn1 }}</a></p>
                      </div>
                    </div> 
                  </div> 
                </div>
              </div> 
         </b-carousel-slide>
      </b-carousel>
    </div>

 <!--  <div class="untree_co--hero overlay" style="background-image: url('images/hero_bg.jpg')">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-7"></div>
        <div class="col-md-5 ml-auto">
          <div class="intro">
            <h1 class="mb-4" data-aos="fade-left" data-aos-delay="100"><strong>We Figh For Your Right</strong></h1>
            <div class="intro-desc" data-aos="fade-left" data-aos-delay="200"><p class="mb-4">Another cool free template by the fine folks at.</p>
              <p><a href="#" class="btn btn-primary" style="background-color:#00ccff;border:none;color:#ffffff !important;padding:20px;border-radius:40px;">Free Consultation</a></p>

            
            </div>
          </div> 
        </div> 
      </div>
    </div> 
    
  </div>  -->
  
  <div class="untree_co-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 mb-4 mb-lg-0">
          <h2 class="heading" style="color:#000000;">{{ homeTituloPreto }} <strong class="" style="color:#00ccff;">{{ homeTituloAzul }}</strong></h2>
          <div class="px-md-5" data-aos="fade-right" data-aos-delay="100">
            <p v-html="homeTexto"></p>
            <p><a :href="homeLink" class="btn btn-primary" style="background-color:#00ccff;border:none;color:#ffffff !important;padding:15px;border-radius:30px;">{{ homeBtn }}</a></p>
          </div>
        </div> <!-- /.col-lg-6 -->
        <div class="col-lg-5 ml-auto js-hover-focus-one">
          <div v-for="item in IconesDireita" :key="item.institucionalId" href="#" class="service-sm align-items-center mb-3">
            <div style="display:inline-flex;">
              <span class="wrap-icon" style="background-color: #b2b2b8;">
                <img v-if="item.institucionalImg!=''" :src="$apiUrlIndex + item.institucionalImg[0].urlImagem">
              </span>
              <div>
                <div class="text">{{ item.nome}}</div>
                <p v-html="item.resumo1"></p>
              </div>
            </div>
          </div>
          <p class="pl-3" data-aos="fade-left" data-aos-delay="200" style="float: right;">
            <router-link to="/atuacao" style="color:#b2b2b8 !important;">
              Veja Mais
            </router-link>
          </p> 

        </div> <!-- /.col-lg-6 -->
      </div> <!-- /.row -->
    </div> <!-- /.container -->
  </div> <!-- /.untree_co-section -->
  
  <div class="untree_co-section pt-0 count-numbers">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5">
          <h2 class="heading" style="color:#000000;"><strong class="" style="color:#00ccff;">{{ expTituloAzul }}</strong> {{ expTituloPreto }}</h2>
        </div> 
        <div class="col-lg-7">
          <div class="row">
            <div v-for="numero in numeros" :key="numero.institucionalId" class="col-md-6 col-lg-6 ml-auto">
              <span class="display-2"><span class="counter">{{ numero.referencia }}</span></span>
              <strong class="d-block mt-2">{{ numero.nome }}</strong>
              <p v-html="numero.resumo1" style="height:100px;"></p>
            </div> 
          </div>
        </div>
      </div> 
    </div> 
  </div> 

  <div class="container mb-5">
    <div class="untree_co-cta overlay" :style="{ backgroundImage: 'url('+ callImg + ')' }">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-lg-7">
          <h2 class="mb-4" style="color:#ffffff !important;" v-html="callTexto"></h2>
          <p class="mb-0"><a :href="callLink" class="btn btn-primary" style="background-color:#00ccff;border:none;color:#ffffff !important;padding:15px;border-radius:30px;z-index:99999;position: relative;">{{ callBtn }}</a></p>
        </div> 
      </div> 
    </div> 
  </div> 
  
  </div>
</template>

<script>
import Header from '../shared/Header.vue'
//import FiltraImoveis from '../shared/FiltraImoveis.vue'
// import Depoimentos from '../shared/Depoimentos.vue'
import moment from 'moment'
//import { Carousel, Slide } from 'vue-carousel';


export default {
  components: {
    Header,
    //FiltraImoveis,
    // Depoimentos
    //Carousel,
    //Slide
   },
  data() {
    return {
     // topoFundo:'#FFFFFF',
     // topoCor:'ajustacor2',
        topoFundo:'#13204A',
      topoCor:'ajustacor',
      slider: [],
      homeDados:[],
      homeTituloAzul:'',
      homeTituloPreto:'',
      homeTexto:'',
      homeBtn:'',
      homeLink:'',
      IconesDireita:[],
      homeExperiencia:[],
      expTituloPreto:'',
      expTituloAzul:'',
      expBtn:'',
      expLink:'',
      numeros:[],
      CallAction:[],
      callTexto:'',
      callBtn:'',
      callLink:'',
      callImg:'',
     
      socialFacebook: [],
      facebookLink: '',

      socialInstagram: [],
      instagramLink: '',

      socialYoutube: [],
      youtubeLink: '',

      socialTwitter: [],
      twitterLink: '',
    
      
    }
  },
  created() {

   this.$http
    .get(this.$apiUrl + "/institucional/telas/slider")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.slider = retornoAPI.filter((x) => x.visivel)

      this.slider.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
    });

     this.$http
    .get(this.$apiUrl + "/institucional/telas/textoesquerda")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.homeDados = retornoAPI

      this.homeTituloPreto = this.homeDados[0].nome
      this.homeTituloAzul = this.homeDados[0].referencia
      this.homeTexto = this.homeDados[0].descricao1
      this.homeBtn = this.homeDados[0].textoBtn1
      this.homeLink = this.homeDados[0].linkBtn1
      //this.sobreImg = this.$apiUrlIndex + this.sobre[0].institucionalImg[0].urlImagem
    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/iconesdireita")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.IconesDireita = retornoAPI.filter((x) => x.visivel)

      this.IconesDireita.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
    });

   this.$http
    .get(this.$apiUrl + "/institucional/telas/títuloexperiência")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.homeExperiencia = retornoAPI

      this.expTituloPreto = this.homeExperiencia[0].nome
      this.expTituloAzul = this.homeExperiencia[0].referencia
      this.expBtn = this.homeExperiencia[0].textoBtn1
      this.expLink = this.homeExperiencia[0].linkBtn1
      //this.sobreImg = this.$apiUrlIndex + this.sobre[0].institucionalImg[0].urlImagem
    });

     this.$http
    .get(this.$apiUrl + "/institucional/telas/numeros")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.numeros = retornoAPI.filter((x) => x.visivel)

      this.numeros.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
    });

   this.$http
    .get(this.$apiUrl + "/institucional/telas/callactionhome")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.CallAction = retornoAPI

      this.callTexto = this.CallAction[0].resumo1
      this.callBtn = this.CallAction[0].textoBtn1
      this.callLink = this.CallAction[0].linkBtn1
      if (this.CallAction[0].institucionalImg!= ''){
         this.callImg = this.$apiUrlIndex + this.CallAction[0].institucionalImg[0].urlImagem
      }
      
    });
    

 /*    let dadosArmazenados = ''
    dadosArmazenados = localStorage.getItem('dadosBuscaArtur');
    if (dadosArmazenados) {
      localStorage.removeItem('dadosBuscaArtur');
    } 

    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topbar = retornoAPI

      this.topbarTelefone = this.topbar[0].nome
      this.topbarEmail = this.topbar[0].campoExtra1
      this.topo_bg = this.$apiUrlIndex + this.topbar[0].institucionalImg[0].urlImagem

    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/cad%20imoveis")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.imoveisEmDestaque = retornoAPI.filter((x) => x.visivel && x.destaque)
      this.imoveisRecentes = retornoAPI.filter((x) => x.visivel).reverse()

      
    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/nossos%20servicos%20fundo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.nossosServicos = retornoAPI

      this.nossosServicosTitulo = this.nossosServicos[0].nome
      this.nossosServicos_bg = this.$apiUrlIndex + this.nossosServicos[0].institucionalImg[0].urlImagem
      this.nossosServicosImg = this.$apiUrlIndex + this.nossosServicos[0].institucionalImg[1].urlImagem
    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/nossos%20servicos%20itens")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.nossosServicosItens = retornoAPI.filter((x) => x.visivel)

      this.nossosServicosItens.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })

      if(this.$apiUrlIndex + this.nossosServicosItens[0].institucionalImg[0].urlImagem !='') {
        this.teste = true
      }

    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/tipo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.tiposDeImoveis = retornoAPI.filter((x) => x.visivel)

      this.tiposDeImoveis.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })

    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/noticias")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.blog = retornoAPI.filter((x) => x.visivel).reverse()

    });


    // Sociais
    this.$http
    .get(this.$apiUrl + "/institucional/telas/facebook")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialFacebook = retornoAPI.filter((x) => x.visivel)

      this.facebookLink = this.socialFacebook[0].nome

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/instagram")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialInstagram = retornoAPI.filter((x) => x.visivel)

      this.instagramLink = this.socialInstagram[0].nome

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/youtube")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialYoutube = retornoAPI.filter((x) => x.visivel)

      this.youtubeLink = this.socialYoutube[0].nome

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/twitter")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.socialTwitter = retornoAPI.filter((x) => x.visivel)

      this.twitterLink = this.socialTwitter[0].nome

    }); */


  },
  methods: {

    currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    },
       /*  CarregaBuscaRapida() {
            let ObjBusca = [];
            ObjBusca.push({
              codigo: null,
              TipoNegocio: 2,
              TipoImovel: this.TipoImovelId,
              ValorDe: this.valorDe,
              ValorAte: this.valorAte,
              Estado: this.Estadoselected,
              Cidade: this.Cidadeselected,
              Bairro: this.Bairro,
            });
            localStorage.setItem("dadosBuscaArtur", JSON.stringify(ObjBusca));

            let vtexto = "";
            if (this.TipoId == 2) {
              vtexto = "Compra";
            } else if (this.TipoId == 3) {
              vtexto = "Investimento";
            } else {
              vtexto = "";
            }
            this.$redirect_reload("/imoveis/" + this.TipoId + "/" + vtexto);
    },
 */
  }
  
}

</script>

<style>

.text{
  font-size: 20px;
  color:#00ccff !important;
  font-weight: 500;
}

</style>