<template>

  <div>

   <Header :topoFundo="topoFundo" :topoCor="topoCor" />

   <!-- Breadcrumb -->
    <div class="site-breadcrumb" style="height:200px;background-color:#295089;">
      <div class="container">
        <div class="row">
          <div class="col-md-12" style="text-align:center;padding-top:50px;">
               <span style="color:#ffffff;font-size:45px;">{{ topoTitulo}}</span>
          </div>
        </div>
        
      </div>
    </div>

  <section class="blog_area spad-100" style="padding-top:60px;padding-bottom:90px;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="blog_post">
                            <div class="blog_details">
                                <a href="#">
                                  <h2 class="heading" style="color:#000000;"><strong class="" style="color:#00ccff;">{{ artigo_titulo }}</strong></h2>
                                </a>
                                <img class="w-100 set-img mt-5 mb-4" :src="artigo_imagem" alt="">
                                <p v-html="artigo_texto"></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div style="padding:30px;background-color:#e4e9f0;">
                            <aside class="single_sidebar_widget popular_post_widget">
                                <h5 style="padding-top:20px;padding-bottom:20px;border-bottom:1px solid #cccccc;">Últimas</h5>
                                <div class="media post_item d-flex mt-3"  v-for="noticia in blog" :key="noticia.id">
                                     <a href="#" @click="carregaArtigo(noticia.institucionalId)">
                                    <img v-if="$apiUrlIndex + noticia.institucionalImg[0].urlImagem !=''" style="width:100px;padding-bottom:10px;padding-right:10px;" :src="$apiUrlIndex + noticia.institucionalImg[0].urlImagem">
                                    <img v-else src="/img/indisponivel.jpg" >
                                     </a>
                                    <div class="media-body">
                                        <a href="#" @click="carregaArtigo(noticia.institucionalId)">
                                            <h6>{{ noticia.nome }}</h6>
                                        </a>
                                        <p style="color:#333333;font-size:10px;">{{ currentDateTime(noticia.data1) }}</p>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  </div>
</template>

<script>
import Header from '../shared/Header.vue'
// import Depoimentos from '../shared/Depoimentos.vue'
import moment from 'moment'

export default {
  components: {
    Header
  },
  data() {
    return {
      topoFundo:'#13204A',
      topoCor:'ajustacor',
      topoInternas: [],
      topoTitulo: '',
      topo_bg: '',
      artigo: [],
      artigo_titulo: '',
      artigo_texto:'',
      artigo_imagem:'',
      blog: [],

    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20blog")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/noticias")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.blog = retornoAPI.filter((x) => x.visivel).reverse()

      if (this.blog != ''){
         this.artigo_titulo = this.blog[0].nome;
         this.artigo_texto = this.blog[0].descricao1;
         this.artigo_imagem= this.$apiUrlIndex + this.blog[0].institucionalImg[0].urlImagem;
      }
    });

  },
  methods: {
        carregaArtigo(idArtigo) {
                this.$http
                    .get(this.$apiUrl + "/institucional/" + idArtigo)
                    .then((res) => res.json())
                    .then((retornoAPI) => {
                        this.artigo = retornoAPI
                        this.artigo_titulo = this.artigo.nome
                        this.artigo_texto = this.artigo.descricao1;
                        this.artigo_data = this.artigo.data1;

                        if (this.artigo.institucionalImg != '') {
                            this.artigo_imagem = this.$apiUrlIndex + this.artigo.institucionalImg[0].urlImagem;
                        }
                        else {
                            this.artigo_imagem = '';
                        }

                    });
            },
    currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    }

  }
  
}

</script>

