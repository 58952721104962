<template>

  <div>

  <Header :topoFundo="topoFundo" :topoCor="topoCor" />

    <!-- Breadcrumb -->
    <div class="site-breadcrumb" style="height:200px;background-color:#295089;">
      <div class="container">
        <div class="row">
          <div class="col-md-12" style="text-align:center;padding-top:50px;">
               <span style="color:#ffffff;font-size:45px;">{{ topoTitulo}}</span>
          </div>
        </div>
        
      </div>
    </div>
    <div class="container" style="padding-top:60px;padding-bottom:90px;">
       <!--  <div class="row">
              <div class="col-md-12 mt-5 mb-5" style="text-align:center;">
                <h1 class="heading" style="color:#000000;">Formulário de <strong class="" style="color:#00ccff;">Contato</strong> </h1>
            </div>
         </div> -->
         <div class="row">
           <div class="col-md-6 mb-5" style="text-align:center;">
               <p v-html="contatoEndereco"></p>
                <p v-html="contatoTelefones"></p>
           </div>
           <div class="col-md-6 mb-5" style="text-align:left;">
            
              <div class="contact-form" style="padding:30px;background-color:#e4e9f0;">
                <div class="row">
                   <div class="col-12 mb-3">
                      <p v-html="contatoAtendimento"></p>
                  </div>
                  <div class="col-12 mb-3">
                    <div class="form-group">
                      <input type="text" v-model="nome" class="form-control" placeholder="Nome">
                    </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div class="form-group">
                      <input type="text" v-model="telefone" class="form-control" placeholder="Telefone">
                   </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div class="form-group">
                      <input type="email" v-model="email" class="form-control" placeholder="E-mail">
                   </div>
                  </div>
                  <div class="col-12 mb-3">
                    <div class="form-group">
                       <textarea v-model="mensagem" rows="30" class="form-control" style="height:200px;" placeholder="Mensagem"> </textarea>
                   </div>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="form-group">
                       <button type="button" class="btn btn-primary" @click="enviarDadosFormulario" style="background-color:#00ccff;border:none;color:#ffffff !important;padding:15px;border-radius:40px;">Enviar contato</button>
                   </div>
                  </div>
                </div>
                 
              </div>
           </div>
         </div>
    </div>
  </div>
</template>

<script>
import Header from '../shared/Header.vue'
// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
    Header
  },
  data() {
    return {
      topoFundo:'#13204A',
      topoCor:'ajustacor',
      topoInternas: [],
      topoTitulo: '',
      topo_bg: '',

      contato: [],
      contatoEndereco: '',
      contatoTelefones: '',
      contatoAtendimento: '',
      contatoImg: '',
      contatoEmail: '',
      contatoMapa:'',

      nome: '',
			email: '',
			telefone: '',
			mensagem: '',


      // Objeto Contato      
      Contato: {
        NomeEmpresa: "Luzardo Advogados Associados ",
        Assunto: "Contato",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/topo%20contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoTitulo = this.topoInternas[0].nome

    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/info%20contato")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.contato = retornoAPI
      
      this.contatoEmail = this.contato[0].referencia
      this.contatoEndereco = this.contato[0].descricao1
      this.contatoTelefones = this.contato[0].descricao2
      this.contatoAtendimento = this.contato[0].descricao4
      //this.contatoImg = this.$apiUrlIndex + this.contato[0].institucionalImg[0].urlImagem
      //this.contatoMapa = "https://goo.gl/maps/ysLEmvJsBUMoE5yg9"; //this.contato[0].descricao2;
    });

  },
  methods: {
    enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      this.Contato.Destinatario = this.contatoEmail
      // this.Contato.Destinatario = "thiagokdma@gmail.com"

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail = 
        "Nome: " + this.nome + 
        " <br /> Telefone: " + this.telefone +
        " <br /> Email: " + this.email +
        " <br /> Descrição: " + this.mensagem

      //validar vazios
      if(this.nome == '' || this.telefone == '' || this.email == '' || this.mensagem == '') {
        this.$mensagem_normal("Preencha todos os campos");
      
      }else {

        //SE NAO TIVER ANEXO:
        // console.log(this.Contato)

        this.$http.post(this.$apiUrl +"/contato/enviamail", this.Contato)
        .then(response => {
          if(response.ok)
            alert("Enviado com sucesso!")
            this.nome = ''
            this.telefone = ''
            this.email = ''
            this.mensagem = ''
            this.Contato.Assunto=""
        },error => {
          alert(error.bodyText)
          this.nome = ''
          this.telefone = ''
          this.email = ''
          this.mensagem = ''
          this.Contato.Assunto=""
        });

      }
     
    }
  }
  
}

</script>

