<template>
 <b-navbar toggleable="lg" class="ftco_navbar ftco-navbar-light" :style="{backgroundColor: topoFundo }">
      <b-navbar-brand>
        <router-link to="/" class="navbar-brand">
          <img class="logo-topo" src="/images/logo.png" alt="Logo" style="height: 78px;"  />
        </router-link>
          <b-navbar-toggle target="nav-collapse"><i class="fa fa-bars" ></i></b-navbar-toggle>
      </b-navbar-brand>

    

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="navbar-nav menu-xl ml-auto">
            <b-nav-item exact to="/" :class="topoCor">Home</b-nav-item>
            <b-nav-item to="/sobre" :class="topoCor">Sobre nós</b-nav-item>
                <b-nav-item-dropdown :class="topoCor" id="my-nav-dropdown" text="Áreas de Atuação" toggle-class="nav-link-custom" right>
                    <b-dropdown-item v-for="servico in servicos" :key="servico" class="margemleft" @click="$redirect_reload('/atuacao/' + servico.institucionalId + '/' + servico.nome.replaceAll(' ','-').replaceAll('/','') + '-' + servico.referencia.replaceAll(' ','-').replaceAll('/',''))">{{ servico.nome}} {{ servico.referencia}}</b-dropdown-item>
              </b-nav-item-dropdown>
             <!-- <b-nav-item to="/servicos" :class="topoCor">Serviços</b-nav-item>       -->
            <b-nav-item to="/blog" :class="topoCor">Blog</b-nav-item>
            <b-nav-item to="/contato" :class="topoCor">Contato</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar> 
</template>

<script>
// import FiltraImoveis from './FiltraImoveis.vue'

export default {
  props: ['topoFundo','topoCor'],
  components: {
    // FiltraImoveis
  },
  data() {
    return {
      selectedTipo: 'Tipo de Imóvel',
      selectedCidade: 'Tipo de Cidade',
      selectedBairro: 'Tipo de Bairro',
      selectedMin: 'De: R$',
      selectedMax: 'Até: R$',
       options: [
        'foo',
        'bar',
        'baz'
      ],

      topbar: [],
      topbarTelefone: '',
      topbarEmail: '',

      socialFacebook: [],
      facebookLink: '',

      socialInstagram: [],
      instagramLink: '',

      socialYoutube: [],
      youtubeLink: '',

      socialTwitter: [],
      twitterLink: '',

      servicos:[],
    }

  },
  created() {
    
this.$http
    .get(this.$apiUrl + "/institucional/telas/cadastro%20areas")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.servicos = retornoAPI.filter((x) => x.visivel)

      this.servicos.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })

    });

 }

}
</script>

<style>

.navbar-light .navbar-toggler {
    color: #ffffff !important;
    border-color: #ffffff !important;
}

.ajustacor a {
  color: #FFFFFF !important; 
}

.ajustacor2 a {
  color: #333333 !important; 
}
.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.navbar-brand {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 25% !important;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav li {
    padding-left: 20px;
    font-size: 18px;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: transparent !important;
}

.dropdown-item:hover, .dropdown-item:focus {
    color: #1e2125;
    background-color: transparent !important;
}

@media (max-width: 991.98px) {
  .logo-topo{
    width: 85%;
  }
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0 !important;
    margin-bottom: 0;
   
    list-style: none;
}

.navbar-nav li {
    padding-left: 20px;
    font-size: 18px;
     border-bottom:1px solid #cccccc;
}
}

.margemleft {
    padding-left: 0px !important;
    font-size: 17px !important;
}

</style>